import { useNavigate } from 'react-router-dom'

function Error({
  title,
  message,
  buttons,
}: {
  title: string
  message: string
  buttons: { [action: string]: string }
}) {
  const navigate = useNavigate()

  return (
    <div>
      <h1>{title}</h1>
      <p>{message}</p>
      {Object.entries(buttons).map(([action, link]) => (
        <button onClick={() => navigate(link)} key={link}>
          {action}
        </button>
      ))}
    </div>
  )
}

export default Error
