import { Box, Button, Divider, LinearProgress, Stack, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import React from 'react'
import { recentTransactions } from 'utils/transactions'

import { Stocks } from 'types/stock'
import { useNavigate } from 'react-router-dom'
import { stockMetadata } from 'utils/stocks'

function RealtimeChart() {
  const navigate = useNavigate()

  React.useEffect(() => {
    ;(async function () {
      const transactions = await recentTransactions()
      setTransactions(transactions)
      setLoading(false)
    })()
  }, [])

  const [loading, setLoading] = React.useState(true)
  const [transactions, setTransactions] = React.useState<{
    [stock in Stocks]: number
  }>({ A: 0, B: 0, C: 0, D: 0, E: 0, F: 0, G: 0, H: 0, I: 0 })

  return (
    <Stack spacing={1}>
      <Typography variant='h6'>
        <b>실시간 거래량</b>
      </Typography>
      {loading ? (
        <Stack justifyContent={'center'}>
          <LinearProgress />
          <Box sx={{ height: 200 }} />
          <Typography sx={{ color: '#757575' }} align='center'>
            불러오는 중...
          </Typography>
        </Stack>
      ) : (
        <Stack spacing={1}>
          <Divider />
          {Object.entries(transactions).map(([stock, count], index) => (
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              key={index}
              component={Button}
              sx={{ color: 'black' }}
              onClick={() => navigate('/stock/' + stock)}
            >
              <Stack direction='row' alignItems='center' spacing={2}>
                <Box sx={{ width: '15px' }}>
                  <Typography sx={{ color: '#3182f7' }}>
                    <b>{index + 1}</b>
                  </Typography>
                </Box>
                <img src={stockMetadata[stock as Stocks].img} height='40px' />
                <Stack direction='column' alignItems='left'>
                  <Typography fontSize={17} align='left'>
                    <b>{stockMetadata[stock as Stocks]['name']}</b>
                  </Typography>
                  <Typography fontSize={14} sx={{ color: '#757575' }} align='left'>
                    최근 {count}회 거래
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNextIcon fontSize='small' sx={{ color: '#757575' }} />
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  )
}

export default RealtimeChart
