import { Box, Button, Divider, LinearProgress, Stack, Typography } from '@mui/material'
import { getAuth } from 'firebase/auth'
import useRanking from 'hooks/useRanking'
import React from 'react'
import { moneyFormat } from 'utils/format'
import { defaultUserData } from 'utils/stocks'

const initialMoney = defaultUserData.balance

function Ranking() {
  const auth = getAuth()
  const user = auth.currentUser
  const uid = user?.uid
  const ranking = useRanking()
  //   React.useEffect(() => {
  //     if (ranking !== undefined) {
  //       console.log(ranking)
  //     }
  //   }, [ranking])

  const [myStockRank, setMyStockRank] = React.useState(0)

  React.useEffect(() => {
    if (ranking !== undefined) {
      const myRank = ranking.findIndex((user) => user.uid === uid) + 1
      if (myRank !== myStockRank) setMyStockRank(myRank)
    }
  }, [ranking])

  return (
    <Stack spacing={1}>
      <Typography variant='h6'>
        <b>보유자산 랭킹</b>
      </Typography>
      <Typography sx={{ color: '#757575' }}>
        *닉네임 변경 시 게임 시간으로 하루 뒤 적용
        <br />
        *지연 존재 (~5min)
      </Typography>
      {ranking === undefined ? (
        <Stack justifyContent={'center'}>
          <LinearProgress />
          <Box sx={{ height: 200 }} />
          <Typography sx={{ color: '#757575' }} align='center'>
            불러오는 중...
          </Typography>
        </Stack>
      ) : (
        <Stack spacing={1}>
          <Divider />
          {ranking.map((user, index) => {
            return (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                key={index}
                component={Button}
                sx={{ color: 'black' }}
              >
                <Stack direction='row' alignItems='center' spacing={2}>
                  <Box sx={{ width: '30px' }}>
                    <Typography
                      sx={{
                        color:
                          user.name.includes('OP') || user.name.includes('AI')
                            ? '#fc2c4c'
                            : user.name.includes('선생님') || user.name.includes('티쳐')
                            ? '1ed760'
                            : '#3182f7',
                      }}
                    >
                      <b>
                        {user.name.includes('OP')
                          ? 'OP'
                          : user.name.includes('AI')
                          ? 'AI'
                          : user.name.includes('선생님') || user.name.includes('티쳐')
                          ? 'T'
                          : index + 1}
                      </b>
                    </Typography>
                  </Box>
                  <Stack direction='column'>
                    <Typography fontSize={17} align='left'>
                      <b>
                        {user.nickname} - {user.name}
                      </b>
                    </Typography>
                    <Typography fontSize={14} sx={{ color: '#757575' }} align='left'>
                      총 자산 {moneyFormat(user.asset)}원 (수익률{' '}
                      {(((user.asset - initialMoney) / initialMoney) * 100).toFixed(3)}%)
                    </Typography>
                  </Stack>
                </Stack>
                {/* <NavigateNextIcon fontSize='small' sx={{ color: '#757575' }} /> */}
              </Stack>
            )
          })}
          <div style={{ height: '50px' }} />

          <div
            className='fd'
            style={{
              backgroundColor: '#f3f4f6',
              // padding: 10,
              // paddingTop: 10,
              // paddingBottom: 10,
              borderRadius: 15,
            }}
          >
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              component={Button}
              sx={{ color: 'black', width: '80%' }}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                <Stack direction='column'>
                  <Typography fontSize={17} align='left'>
                    <b>나의 등수:</b>
                  </Typography>
                </Stack>
                <Box
                  sx={{ background: '#3182f7', borderRadius: 1, color: 'white', px: 1, py: 0.3 }}
                >
                  <Typography>
                    <b>{myStockRank}등</b>
                  </Typography>
                </Box>
                <Typography fontSize={17} align='left'>
                  (총 {ranking.length}명 중)
                </Typography>
              </Stack>
              {/* <NavigateNextIcon fontSize='small' sx={{ color: '#757575' }} /> */}
            </Stack>
          </div>
        </Stack>
      )}
    </Stack>
  )
}

export default Ranking
