import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { Toaster } from 'react-hot-toast'

import Home from 'pages/Home'
import SignIn from 'pages/SignIn'
import SignUp from 'pages/SignUp'
import Error from 'pages/Error'
import RealtimeChart from 'pages/RealtimeChart'
import AuthRequired from 'pages/AuthRequired'
import { Grid, ThemeProvider } from '@mui/material'
import { theme } from 'assets/theme'
import Detail from 'pages/Detail'
import Buy from 'pages/Buy'
import Sell from 'pages/Sell'
import Ranking from 'pages/Ranking'
import ChangeNick from 'pages/ChangeNick'

function App() {
  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path='signin' element={<SignIn />} />
              <Route path='signup' element={<SignUp />} />
              <Route path='' element={<Navigate to='signin' />} />
              <Route
                path='*'
                element={
                  <Error
                    title='404 not found'
                    message='No matching page.'
                    buttons={{ 홈으로: '/stock/home' }}
                  />
                }
              />
              <Route path='stock' element={<AuthRequired />}>
                <Route path='home' element={<Home />} />
                <Route path='changenick' element={<ChangeNick />} />
                <Route path='charts' element={<RealtimeChart />} />
                <Route path=':id' element={<Detail />} />
                <Route path='buy'>
                  <Route path=':id' element={<Buy />} />
                </Route>
                <Route path='sell'>
                  <Route path=':id' element={<Sell />} />
                </Route>
                <Route path='ranking' element={<Ranking />} />
              </Route>
            </Routes>
            <Toaster position='top-center' />
            {/* <Toaster position='bottom-left' /> */}
          </BrowserRouter>
        </ThemeProvider>
      </Grid>
    </Grid>
  )
}

export default App
