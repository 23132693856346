import React from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from 'services/authentication'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { FirebaseError } from 'firebase/app'
import useUserData from 'hooks/useUserData'
import { Button, Paper, Stack, Typography } from '@mui/material'

import { gcloud, logo } from 'img'

function SignIn() {
  const navigate = useNavigate()
  if (useUserData()) navigate('/stock/home')

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handle: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    handle(event.target.value)
  }

  const handleSignInButtonClick = async () => {
    toast.promise(signInWithEmailAndPassword(auth, email, password), {
      loading: '로그인 중',
      success: '로그인했어요',
      error: (error: unknown) => {
        if (error instanceof FirebaseError) {
          if (error.code === 'auth/wrong-password') return '잘못된 비밀번호에요'
          if (error.code === 'auth/user-not-found') return '이용자를 찾을 수 없어요'
          if (error.code === 'auth/invalid-email') return '이메일 형식이 잘못되었어요'
          if (error.code === 'auth/too-many-requests')
            return '계정을 잠시 잠갔어요. 몇 분 후 다시 시도하세요.'
        }
        return '예상치 못한 오류가 발생했어요'
      },
    })
  }

  return (
    <Stack direction='column' spacing={2} p={3}>
      <Stack direction='column' spacing={2} m={5}>
        <Stack sx={{ width: 'inherit' }} alignItems='center' spacing={1}>
          <img src={logo} alt='logo' width='40px' height='40px' />
        </Stack>
        <Typography variant='h6' align='center'>
          두루누리에 로그인
        </Typography>
      </Stack>
      <Paper sx={{ backgroundColor: 'black' }}>
        <Stack direction='column' spacing={1}>
          <Typography>이메일</Typography>
          <input
            type='email'
            value={email}
            style={{ marginTop: 0 }}
            onChange={(e) => handleInputChange(e, setEmail)}
          />
          <Typography>패스워드</Typography>
          <input
            type='password'
            value={password}
            style={{ marginTop: 0, marginBottom: 10 }}
            onChange={(e) => handleInputChange(e, setPassword)}
          />
          <button onClick={handleSignInButtonClick}>로그인</button>
        </Stack>
      </Paper>
      <Button
        onClick={() => {
          navigate('/signup')
        }}
      >
        회원가입
      </Button>
      <Stack direction='row' justifyContent='center' spacing={1}>
        <Typography fontSize={12} sx={{ color: '#b1b1b1' }}>
          Secured by{' '}
        </Typography>
        <img src={gcloud} className='grayscale' height='18px' />
      </Stack>
    </Stack>

    // <div>
    //   <h1>Sign in</h1>
    //   <input type='email' value={email} onChange={(e) => handleInputChange(e, setEmail)} />
    //   <input type='password' value={password} onChange={(e) => handleInputChange(e, setPassword)} />
    //   <button onClick={handleSignInButtonClick}>로그인</button>
    //   <button onClick={() => navigate('/signup')}>회원가입</button>
    // </div>
  )
}

export default SignIn
