export function moneyFormat(value: number, easyview = false) {
  if (value == 0) return '0'
  if (easyview) {
    const man = String(Math.floor(value / 10000))
    const cheon = String(Math.floor((value % 10000) / 1000))
    const baek = String(Math.floor((value % 1000) / 100))
    return (
      (!['0', '1'].includes(man) ? man + '만' : '') +
      (cheon !== '0' ? (cheon !== '1' ? cheon : ' ') + '천' : '') +
      (baek !== '0' ? (baek !== '1' ? baek : ' ') + '백' : '') +
      ' '
    )
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const dateFormatter = new Intl.DateTimeFormat('ko-KR', {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour12: false,
  timeZone: 'Asia/Seoul',
})
