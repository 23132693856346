import * as React from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from 'services/database'
import { UserData } from 'types/stock'

function useRanking() {
  const [rankingData, setRankingData] = React.useState<UserData[] | undefined>(undefined)

  React.useEffect(() => {
    const rankingRef = doc(db, 'config', 'ranking')
    onSnapshot(rankingRef, (doc) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const data = doc.data()!.total as UserData[]
      setRankingData(data)
    })
  })

  return rankingData
}

export default useRanking
