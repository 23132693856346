import * as React from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from 'services/database'
import useUserData from 'hooks/useUserData'
import { StockData } from 'types/stock'

function useStock() {
  const userData = useUserData()
  const [stockData, setStockData] = React.useState<StockData | undefined>(undefined)

  React.useEffect(() => {
    if (userData) {
      const stockRef = doc(db, 'config', 'stock')
      onSnapshot(stockRef, (doc) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const data = doc.data()!
        data.lastupdate = data.lastupdate.toDate()
        setStockData(data as StockData)
      })
    }
  }, [userData])

  return stockData
}

export default useStock
