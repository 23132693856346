import stockA from 'data/GS.json'
import stockB from 'data/LG생활건강.json'
import stockC from 'data/NAVER.json'
import stockD from 'data/POSCO.json'
import stockE from 'data/삼성전자.json'
import stockF from 'data/셀트리온.json'
import stockG from 'data/에스엠.json'
import stockH from 'data/현대차.json'
import stockI from 'data/NCSOFT.json'

import newsA from 'data/news/GS.json'
import newsB from 'data/news/LG생활건강.json'
import newsC from 'data/news/NAVER.json'
import newsD from 'data/news/POSCO.json'
import newsE from 'data/news/삼성전자.json'
import newsF from 'data/news/셀트리온.json'
import newsG from 'data/news/에스엠.json'
import newsH from 'data/news/현대차.json'
import newsI from 'data/news/NCSOFT.json'

import imgDY from 'img/대용.png'
import imgDH from 'img/동하.png'
import imgBH from 'img/봉형.png'
import imgSJ from 'img/승재.png'
import imgEM from 'img/에밀리.png'
import imgPE from 'img/정환.png'
import imgJH from 'img/주형.png'
import imgYG from 'img/temp.png'
import imgMH from 'img/민혁.png'

import { doc, updateDoc, runTransaction, collection, serverTimestamp } from 'firebase/firestore'
import useStock from 'hooks/useStock'
import useUserData from 'hooks/useUserData'
import { toast } from 'react-hot-toast'
import { db } from 'services/database'
import { UserData } from 'types/auth'
import { StockData, HoldingStocks, Stocks } from 'types/stock'

export async function buyStock(stock: Stocks, price: number, amount: number, uid: string) {
  const userRef = doc(db, 'user', uid)
  const transCol = collection(db, 'transaction')
  const keyname = 'stock.' + stock

  await runTransaction(db, async (transaction) => {
    const userDoc = await transaction.get(userRef)
    if (!userDoc.exists()) {
      throw '심각한 오류: 로그아웃 후 다시 로그인하세요'
    }
    const newBalance = userDoc.data().balance - Math.floor(price * amount * 1.00015)
    if (newBalance < 0) {
      throw '잔액이 부족해요'
    }

    const currentStock = userDoc.data().stock[stock] as number[]
    const newStock = currentStock.concat(Array(amount).fill(price))

    transaction.update(userRef, {
      balance: newBalance,
      [keyname]: newStock,
    })

    const newTransRef = doc(transCol)
    transaction.set(newTransRef, {
      buy: true,
      dealOn: serverTimestamp(),
      amount: amount,
      price: price,
      stock: stock,
      uid: uid,
    })
  })
}

export async function sellStock(stock: Stocks, price: number, amount: number, uid: string) {
  const userRef = doc(db, 'user', uid)
  const transCol = collection(db, 'transaction')
  const keyname = 'stock.' + stock

  await runTransaction(db, async (transaction) => {
    const userDoc = await transaction.get(userRef)
    if (!userDoc.exists()) {
      throw '심각한 오류: 로그아웃 후 다시 로그인하세요'
    }
    const newBalance = userDoc.data().balance + price * amount

    const currentStock = userDoc.data().stock[stock] as number[]
    if (currentStock.length < amount) {
      throw '보유한 주식이 부족해요'
    }

    const avgPrice = Math.floor(getArrayAvg(currentStock))
    const newStock = Array(currentStock.length - amount).fill(avgPrice)

    transaction.update(userRef, {
      balance: newBalance,
      [keyname]: newStock,
    })

    const newTransRef = doc(transCol)
    transaction.set(newTransRef, {
      buy: false,
      dealOn: serverTimestamp(),
      amount: amount,
      price: price,
      stock: stock,
      uid: uid,
    })
  })
}

export function getArrayAvg(arr: number[]) {
  if (arr.length === 0) return 0
  return arr.reduce((a, b) => a + b) / arr.length
}

export function currentStockValue(holdingStocks: HoldingStocks, price: StockData) {
  let total = 0
  for (const [stock, amount] of Object.entries(holdingStocks)) {
    total += price[stock as Stocks] * amount.length
  }
  return total
}

export function previousMoneys(holdingStocks: HoldingStocks) {
  let total = 0
  for (const [_, amount] of Object.entries(holdingStocks)) {
    if (amount.length === 0) continue
    total += amount.reduce((a, b) => a + b)
  }
  return total
}

export const stockMetadata = {
  A: { name: '대용케미칼', data: stockA, img: imgDY, news: newsA }, // GS
  B: { name: '주형 Beauty', data: stockB, img: imgJH, news: newsB }, // LG생활건강
  C: { name: '승재소프트', data: stockC, img: imgSJ, news: newsC }, // NAVER
  D: { name: '정환중공업', data: stockD, img: imgPE, news: newsD }, // POSCO
  E: { name: '동하전자', data: stockE, img: imgDH, news: newsE }, // 삼성전자
  F: { name: '봉형제약', data: stockF, img: imgBH, news: newsF }, // 셀트리온
  G: { name: '에밀리 엔터테인먼트', data: stockG, img: imgEM, news: newsG }, // 에스엠
  H: { name: '영균모터스', data: stockH, img: imgYG, news: newsH }, // 현대차
  I: { name: '민혁게임즈', data: stockI, img: imgMH, news: newsI }, // NCSOFT
}

export const defaultStockData: StockData = {
  A: 0,
  B: 0,
  C: 0,
  D: 0,
  E: 0,
  F: 0,
  G: 0,
  H: 0,
  I: 0,
  timenow: 1,
  interval: 300,
  lastupdate: new Date(),
}

export const defaultUserData: UserData = {
  name: '',
  nickname: '',
  email: '',
  balance: 5000000,
  stock: {
    A: [],
    B: [],
    C: [],
    D: [],
    E: [],
    F: [],
    G: [],
    H: [],
    I: [],
  },
}
