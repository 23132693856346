function LoadingAuth() {
  return (
    <div>
      <h1>권한 확인중</h1>
      <p>잠시만 기다려주세요</p>
    </div>
  )
}

export default LoadingAuth
