import React from 'react'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { toast } from 'react-hot-toast'
import { getAuth } from 'firebase/auth'
import { db } from 'services/database'
import { doc, updateDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

function ChangeNick() {
  const navigate = useNavigate()
  const auth = getAuth()
  const user = auth.currentUser
  const uid = user?.uid as string

  const [nickname, setNickname] = React.useState('')
  const [isChanging, setIsChanging] = React.useState(false)

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handle: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    handle(event.target.value)
  }

  const handleChangeNickButtonClick = async () => {
    if (nickname.length == 0) {
      toast.error('닉네임은 1글자 이상으로 설정 가능합니다')
    } else {
      setIsChanging(true)
      const toastID = toast.loading('닉네임 변경 중...')

      const userRef = doc(db, 'user', uid)
      await updateDoc(userRef, {
        nickname: nickname,
      })
      setIsChanging(false)
      toast.success('닉네임 변경이 완료되었습니다', { id: toastID })
      navigate('/stock/home')
    }
  }

  return (
    <Paper>
      <Stack spacing={1}>
        <Typography variant='h6'>
          <b>닉네임 변경</b>
        </Typography>
        <input value={nickname} onChange={(e) => handleInputChange(e, setNickname)} />
        <Button
          onClick={() => handleChangeNickButtonClick()}
          variant='contained'
          sx={{
            borderRadius: 3,
          }}
        >
          확인
        </Button>
      </Stack>
    </Paper>
  )
}

export default ChangeNick
