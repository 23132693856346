import { Stocks } from 'types/stock'
import { collection, getCountFromServer, query, Timestamp, where } from 'firebase/firestore'
import { db } from 'services/database'
import { stockMetadata } from './stocks'

export async function recentTransactions() {
  const transactionRef = collection(db, 'transaction')

  const result: { [key: string]: number } = {}
  for (const name in stockMetadata) {
    const q = query(
      transactionRef,
      where('dealOn', '>=', Timestamp.fromDate(new Date(Date.now() - 1000 * 60 * 10))),
      where('stock', '==', name),
    )
    const snapshot = await getCountFromServer(q)
    const count = snapshot.data().count

    result[name] = count
  }

  const sortedKeys = Object.entries(result)
    .sort((a, b) => b[1] - a[1])
    .reduce((acc: string[], entry) => acc.concat(entry[0]), [])

  const sortedObj: Record<string, number> = {}
  sortedKeys.forEach((key) => {
    sortedObj[key] = result[key]
  })

  return sortedObj as { [stock in Stocks]: number }
}
