import { useLocation, Outlet, Navigate, useNavigate } from 'react-router-dom'

import LogoutIcon from '@mui/icons-material/Logout'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import LoadingAuth from 'pages/LoadingAuth'
import useUserData from 'hooks/useUserData'
import { signOut } from 'firebase/auth'
import { auth } from 'services/authentication'
import toast from 'react-hot-toast'
import { logo } from 'img'
import React from 'react'
import useStock from 'hooks/useStock'
import { defaultStockData } from 'utils/stocks'

function AuthRequired() {
  const metaData = useStock() ?? defaultStockData
  const userData = useUserData()
  const location = useLocation()
  const navigate = useNavigate()

  const [notice, setNotice] = React.useState('업데이트 시간 확인 중...')
  React.useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const diffInMilliseconds = now.getTime() - metaData.lastupdate.getTime()

      const timepast = Math.round(diffInMilliseconds / 1000)
      const timeleft = metaData.interval - timepast

      if (timeleft >= 0) {
        setNotice('다음 등락까지 ' + timeleft)
      } else if (timeleft >= -3) {
        setNotice('다음 등락까지 0')
      } else if (timeleft >= -30) {
        setNotice('서버 대기 중... (' + String(timeleft + 30) + ')')
      } else {
        setNotice('오늘 장은 마감되었어요')
      }
    }, 100)
    return () => clearInterval(interval)
  }, [metaData])

  const handleSignOut = () => {
    toast.promise(signOut(auth), {
      loading: '로그아웃하는 중',
      success: '성공적으로 로그아웃했어요',
      error: '로그아웃 중 오류가 발생했어요',
    })
  }

  if (userData === undefined) {
    return <LoadingAuth />
  } else if (userData === null) {
    return <Navigate to='/signin' state={{ from: location }} replace />
  } else {
    return (
      <Stack direction='column'>
        <Stack direction='row' justifyContent='space-between'>
          <IconButton
            sx={{ borderRadius: 1, height: 35 }}
            size='small'
            onClick={() => handleSignOut()}
          >
            <LogoutIcon />
          </IconButton>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Typography>{notice}</Typography>
          </Stack>{' '}
          <IconButton
            sx={{ borderRadius: 1, height: 35 }}
            size='small'
            onClick={() => navigate('/stock/home')}
          >
            <HomeIcon />
          </IconButton>
        </Stack>
        <Stack m={2} mb={0}>
          <Outlet />
        </Stack>
      </Stack>
    )
  }
}

export default AuthRequired
