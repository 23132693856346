import { Box, Divider, Paper, Slider, Stack, Typography } from '@mui/material'
import useStock from 'hooks/useStock'
import React from 'react'
import ApexCharts from 'react-apexcharts'
import { useNavigate, useParams } from 'react-router-dom'
// import { Stocks } from 'types/stock'
import { dateFormatter, moneyFormat } from 'utils/format'
import { buyStock, defaultStockData, stockMetadata } from 'utils/stocks'

const stocks = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

function Detail() {
  const navigate = useNavigate()

  const { id } = useParams()
  if (!stocks.includes(id)) {
    // if (!stocks.includes(id!)) {
    navigate('/stock/home')
  }

  // const currentStock = id as Stocks
  const currentStock = id
  const stockData = useStock() ?? defaultStockData

  const todayPrice = stockData[currentStock]
  const yesterdayPrice = stockMetadata[currentStock].data[stockData.timenow - 1].OHLC[3]
  const priceDelta = todayPrice - yesterdayPrice

  const realDate = stockMetadata[currentStock].data[stockData.timenow].date
  const todayNews = stockMetadata[currentStock].news[realDate]
  // const todayNews = stockMetadata[currentStock].news['20060706']

  const [range, setRange] = React.useState(30)
  // const [range, setRange] = React.useState < number > (30)
  // const handleSliderChange = (event: Event, newValue: number | number[]) => {
  const handleSliderChange = (event, newValue) => {
    // setRange(newValue as number)
    setRange(newValue)
  }

  const newsprocess = (string) => {
    return string.replace('{company}', stockMetadata[currentStock]['name'])
  }

  const [news, setNews] = React.useState(0)
  React.useEffect(() => {
    const interval = setInterval(() => {
      setNews((prevCounter) => prevCounter + 1)
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  const marks = [
    {
      value: 1,
      label: '1일치',
    },
    {
      value: 30,
      label: '',
    },
    {
      value: stockData.timenow + 1,
      label: '전부',
    },
  ]

  return (
    <Stack spacing={1}>
      <Stack>
        {todayNews !== undefined && todayNews.length > 0 ? (
          <div
            style={{
              backgroundColor: '#f3f4f6',
              padding: 10,
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: 15,
            }}
          >
            <Typography>
              NEWS: <br />
              <b>{newsprocess(todayNews[news % todayNews.length])}</b>
            </Typography>
          </div>
        ) : null}

        {/* <Paper
          sx={{
            backgroundColor: '#f3f4f6',
            padding: 20,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 0,
          }}
        >
        </Paper> */}
        <Box height={10} />
        <Typography variant='h6'>
          <b>{stockMetadata[currentStock]['name']}</b>
        </Typography>
        <Typography fontSize={30}>
          <b>{moneyFormat(todayPrice)}원</b>
        </Typography>
        <Stack direction='row' spacing={0.5}>
          <Typography fontSize={15} sx={{ color: '#757575' }}>
            어제보다
          </Typography>
          <Typography fontSize={15} sx={{ color: priceDelta > 0 ? '#fc2c4c' : '#3182F7' }}>
            {priceDelta > 0 ? '+' : '-'}
            {moneyFormat(Math.abs(priceDelta))}원(
            {Math.floor((Math.abs(priceDelta) / yesterdayPrice) * 100 * 100) / 100}%)
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack>
        <ApexCharts
          type='candlestick'
          height={300}
          series={[
            {
              data: stockMetadata[currentStock].data
                .slice(
                  stockData.timenow + 1 - range >= 0 ? stockData.timenow + 1 - range : 0,
                  stockData.timenow + 1,
                )
                .map((item) => {
                  return {
                    x: item.cnt,
                    y: item.OHLC,
                  }
                }),
            },
          ]}
          options={{
            chart: {
              fontFamily: 'Pretendard',
              // offsetX: -50,
              toolbar: {
                show: false,
              },
              animations: {
                enabled: false,
              },
            },
            plotOptions: {
              candlestick: {
                colors: {
                  upward: '#FC2C4C',
                  downward: '#3182F7',
                },
              },
            },
            xaxis: {
              type: 'numeric',
              // labels: {
              //   formatter: function (input) {
              //     return dateFormatter.format(new Date(input))
              //   },
              // },
            },
            yaxis: {
              opposite: true,
            },
          }}
        />
        <Slider
          getAriaLabel={() => 'View range'}
          value={range}
          min={1}
          max={stockData.timenow + 1}
          onChange={handleSliderChange}
          valueLabelDisplay='on'
          marks={marks}
        />
      </Stack>
      <div className='fd'>
        <button className='fb' onClick={() => navigate('/stock/buy/' + currentStock)}>
          구매하기
        </button>
      </div>
    </Stack>
  )
}

export default Detail
