import React from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import useUserData from 'hooks/useUserData'
import { dateFormatter, moneyFormat } from 'utils/format'
import {
  buyStock,
  currentStockValue,
  defaultStockData,
  defaultUserData,
  getArrayAvg,
  previousMoneys,
  stockMetadata,
} from 'utils/stocks'
import useStock from 'hooks/useStock'
import { Stocks } from 'types/stock'
import ApexCharts from 'react-apexcharts'
import useAuth from 'hooks/useAuth'

function Home() {
  const navigate = useNavigate()
  const user = useAuth()
  const userData = useUserData() ?? defaultUserData
  const stockData = useStock() ?? defaultStockData

  const balance = userData.balance ?? 0
  const stockValue = currentStockValue(userData.stock, stockData)
  const previousMoney = previousMoneys(userData.stock)
  const stockDelta = stockValue - previousMoney

  const totalDelta = balance + stockValue - defaultUserData.balance

  const [easyview, setEasyview] = React.useState(false)
  const [drawer, toggleDrawer] = React.useState(false)
  const drawerBleeding = 56

  const [selected, setSelected] = React.useState<Stocks>('A')
  const selectedDelta =
    stockData[selected] - stockMetadata[selected].data[stockData.timenow - 1].OHLC[3]

  return (
    <Stack direction='column' spacing={2}>
      <Typography variant='h5'>
        <b>
          {userData.name}님의
          <br />
          작고 귀여운 수익이에요
        </b>
      </Typography>
      <Paper>
        <Stack direction='row' justifyContent='space-between'>
          <Typography fontSize={15}>내 주식</Typography>
          <Button
            sx={{ height: 25, color: '#757575', marginRight: -1 }}
            onClick={() => setEasyview(!easyview)}
          >
            <Stack direction='column' spacing={-0.1}>
              <Typography fontSize={13}>숫자표시변경</Typography>
              <Divider />
            </Stack>
          </Button>
        </Stack>
        <Typography fontSize={36}>
          <b>{moneyFormat(stockValue, easyview)}원</b>
        </Typography>
        <Typography fontSize={15} sx={{ color: stockDelta > 0 ? '#fc2c4c' : '#3182F7' }}>
          {stockDelta == 0 ? '' : stockDelta > 0 ? ' +' : ' -'}
          {moneyFormat(Math.abs(stockDelta), easyview)}원(
          {Math.round((previousMoney !== 0 ? stockDelta / previousMoney : 0) * 10000) / 100}%)
          {stockDelta > 0 ? ' 버는' : ' 잃는'} 중
        </Typography>
        {stockValue !== 0 ? (
          <Box>
            <div style={{ height: 50 }} />
            <Stack direction='column' spacing={1}>
              <Typography fontSize={15}>내 주식 시세</Typography>
              {Object.entries(userData.stock).map(([stock, prices]) => {
                const amount = prices as number[]
                if (amount.length > 0) {
                  const currentPrice = stockData[stock as Stocks]
                  const previousPriceAvg = Math.round(
                    amount.reduce((a, b) => a + b) / amount.length,
                  )
                  const priceDelta = currentPrice - previousPriceAvg
                  const deltaRate =
                    Math.round((Math.abs(priceDelta) / previousPriceAvg) * 10000) / 100
                  return (
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      key={stock}
                      component={Button}
                      sx={{ color: 'black' }}
                      onClick={() => {
                        setSelected(stock as Stocks)
                        toggleDrawer(true)
                      }}
                    >
                      <Stack direction='row' alignItems='center' spacing={2}>
                        <img src={stockMetadata[stock as Stocks].img} height='40px' />
                        <Stack direction='column'>
                          <Typography fontSize={17} align='left'>
                            <b>{stockMetadata[stock as Stocks].name}</b>
                          </Typography>
                          <Typography fontSize={14} align='left' sx={{ color: '#757575' }}>
                            {amount.length}주
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <Stack direction='column'>
                          <Typography fontSize={17} align='right'>
                            <b>{moneyFormat(currentPrice, easyview)}원</b>
                          </Typography>
                          <Typography
                            fontSize={14}
                            align='right'
                            sx={{ color: priceDelta > 0 ? '#fc2c4c' : '#3182F7' }}
                          >
                            {priceDelta >= 0 ? '+' : '-'}
                            {moneyFormat(Math.abs(priceDelta), easyview)}원({deltaRate}%)
                          </Typography>
                        </Stack>
                        <NavigateNextIcon fontSize='small' sx={{ color: '#757575' }} />
                      </Stack>
                    </Stack>
                  )
                }
              })}
            </Stack>
          </Box>
        ) : null}
      </Paper>
      <Paper>
        <Typography fontSize={15}>남은 모의투자금</Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography fontSize={26}>
            <b>{moneyFormat(balance, easyview)}원</b>
          </Typography>
          <Button variant='contained' sx={{ height: 35 }} onClick={() => navigate('/stock/charts')}>
            주식{stockValue !== 0 ? ' 더 ' : ' '}사기
          </Button>
        </Stack>
      </Paper>
      <Paper>
        <Typography fontSize={15}>총 자산</Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography fontSize={26}>
            <b>{moneyFormat(balance + stockValue, easyview)}원</b>
          </Typography>
          <Button
            variant='contained'
            sx={{ height: 35 }}
            onClick={() => navigate('/stock/ranking')}
          >
            랭킹 보기
          </Button>
        </Stack>
        <Typography fontSize={15} sx={{ color: totalDelta > 0 ? '#fc2c4c' : '#3182F7' }}>
          {totalDelta == 0 ? '' : totalDelta > 0 ? '+' : '-'}
          {moneyFormat(Math.abs(totalDelta), easyview)}원{totalDelta > 0 ? ' 버는' : ' 잃는'} 중
        </Typography>
      </Paper>
      <Button onClick={() => navigate('/stock/changenick')}>닉네임 바꾸기</Button>
      <Box />
      <SwipeableDrawer
        anchor='bottom'
        open={drawer}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
      >
        <Box p={2}>
          <Stack spacing={2}>
            <Box />
            <Stack spacing={1}>
              <Typography fontSize={22}>
                <b>{stockMetadata[selected].name}</b>
              </Typography>
              <Typography fontSize={16} sx={{ color: '#757575' }}>
                {userData.stock[selected].length}주를 가지고 있어요.
              </Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography fontSize={18}>내가 산 평균 가격</Typography>
              <Typography fontSize={18}>
                {moneyFormat(Math.floor(getArrayAvg(userData.stock[selected])), easyview)}원
              </Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography fontSize={18}>현재가격</Typography>
              <Stack alignItems='end'>
                <Typography
                  fontSize={18}
                  sx={{
                    color:
                      stockData[selected] - getArrayAvg(userData.stock[selected]) > 0
                        ? '#fc2c4c'
                        : '#3182F7',
                  }}
                >
                  {moneyFormat(stockData[selected], easyview)}원
                </Typography>
                <Typography fontSize={15} sx={{ color: selectedDelta > 0 ? '#fc2c4c' : '#3182F7' }}>
                  어제보다
                  {selectedDelta > 0 ? ' +' : ' -'}
                  {moneyFormat(Math.abs(selectedDelta), easyview)}원{' '}
                  {selectedDelta > 0 ? '올랐어요' : '내렸어요'}
                </Typography>
              </Stack>
            </Stack>

            <ApexCharts
              type='candlestick'
              height={200}
              series={[
                {
                  data: stockMetadata[selected].data
                    .slice(
                      stockData.timenow - 29 >= 0 ? stockData.timenow - 29 : 0,
                      stockData.timenow + 1,
                    )
                    .map((item) => {
                      return {
                        x: item.cnt,
                        y: item.OHLC,
                      }
                    }),
                },
              ]}
              options={{
                chart: {
                  fontFamily: 'Pretendard',
                  // offsetX: -50,
                  toolbar: {
                    show: false,
                  },
                  animations: {
                    enabled: false,
                  },
                },
                plotOptions: {
                  candlestick: {
                    colors: {
                      upward: '#FC2C4C',
                      downward: '#3182F7',
                    },
                  },
                },
                xaxis: {
                  type: 'numeric',
                  // labels: {
                  //   show: false,
                  //   formatter: function (input) {
                  //     return dateFormatter.format(new Date(input))
                  //   },
                  // },
                },
                yaxis: {
                  opposite: true,
                  labels: {
                    show: false,
                  },
                },
              }}
            />
            <Box m={-1}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <button
                    style={{ width: '100%' }}
                    className='secondary-button'
                    onClick={() => navigate('/stock/sell/' + selected)}
                  >
                    팔기
                  </button>
                </Grid>
                <Grid item xs={6}>
                  <button
                    style={{ width: '100%' }}
                    onClick={
                      () => navigate('/stock/buy/' + selected)
                      // buyStock(selected, stockData[selected], 5, user?.currentUser, userData.stock[selected])
                    }
                  >
                    더 사기
                  </button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
      </SwipeableDrawer>
    </Stack>
  )
}

export default Home
