import React from 'react'
import { createUserWithEmailAndPassword, UserCredential } from 'firebase/auth'
import { FirebaseError } from 'firebase/app'
import { auth } from 'services/authentication'
import { db } from 'services/database'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { doc, setDoc } from 'firebase/firestore'
import { UserData } from 'types/auth'
import useUserData from 'hooks/useUserData'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import { defaultUserData } from 'utils/stocks'

function SignUp() {
  const navigate = useNavigate()
  if (useUserData()) navigate('/stock/home')

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [name, setName] = React.useState('')
  const [nickname, setNickname] = React.useState('')

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handle: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    handle(event.target.value)
  }

  const handleSignUpButtonClick = async () => {
    const toastId = toast.loading('회원가입 중', { id: 'signUp' })
    let userCred: UserCredential
    try {
      userCred = await createUserWithEmailAndPassword(auth, email, password)
    } catch (error: unknown) {
      let errorMessage = '예상치 못한 오류가 발생했어요'
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/invalid-email') errorMessage = '이메일 형식이 올바르지 않아요'
        if (error.code === 'auth/email-already-in-use') errorMessage = '이미 사용중인 이메일이에요'
        if (error.code === 'auth/weak-password') errorMessage = '비밀번호가 너무 약해요'
      }

      toast.error(errorMessage, { id: toastId })
      return
    }

    toast.loading('회원정보 등록 중', { id: toastId })
    const uid = userCred.user.uid
    const newUserRef = doc(db, 'user', uid)
    const newUserData: UserData = {
      ...defaultUserData,
      name: name,
      nickname: nickname,
      email: email,
    }
    try {
      await setDoc(newUserRef, newUserData)
    } catch (error: unknown) {
      alert(
        '치명적인 오류가 발생했어요. 반드시 이 화면을 스크린샷해 관리자에게 문의하세요. 해당 UID: ' +
          uid,
      )
      toast.error('치명적인 오류가 발생했어요. 반드시 관리자에게 문의하세요.', { id: toastId })
    }
    toast.success('회원가입이 완료되었어요.', { id: toastId })
  }

  return (
    <Stack p={3}>
      <Box m={7}>
        <Typography variant='h6' align='center'>
          <b>두루누리에 회원가입</b>
        </Typography>
      </Box>

      <Paper>
        <Stack spacing={2}>
          <Typography>
            <b>이메일</b>
          </Typography>
          <input
            type='email'
            value={email}
            onChange={(e) => handleInputChange(e, setEmail)}
            style={{ marginTop: 0 }}
          />
          <Typography>
            <b>비밀번호</b>
          </Typography>
          <input
            type='password'
            value={password}
            style={{ marginTop: 0 }}
            onChange={(e) => handleInputChange(e, setPassword)}
          />
          <Typography>
            <b>실명</b>
            <br />
            (선생님은 이름 뒤에 [선생님]을 붙여 주세요.)
          </Typography>
          <input
            value={name}
            onChange={(e) => handleInputChange(e, setName)}
            style={{ marginTop: 0 }}
          />
          <Typography>
            <b>닉네임</b>
          </Typography>
          <input
            value={nickname}
            onChange={(e) => handleInputChange(e, setNickname)}
            style={{ marginTop: 0, marginBottom: 10 }}
          />
          <button onClick={handleSignUpButtonClick}>가입하기</button>
        </Stack>
      </Paper>
      <Button onClick={() => navigate('/signin')} sx={{ marginBottom: 0, paddingBottom: 0 }}>
        로그인
      </Button>
    </Stack>
  )
}

export default SignUp
