import { Box, Stack, Typography } from '@mui/material'
import { getAuth } from 'firebase/auth'
import useStock from 'hooks/useStock'
import useUserData from 'hooks/useUserData'
import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { Stocks } from 'types/stock'
import { moneyFormat } from 'utils/format'
import { buyStock, defaultStockData, defaultUserData, stockMetadata } from 'utils/stocks'

const stocks = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

function Buy() {
  const { id } = useParams()
  const navigate = useNavigate()
  const auth = getAuth()
  const user = auth.currentUser
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const uid = user!.uid
  const userData = useUserData() ?? defaultUserData

  const currentStock = id as Stocks
  const stockData = useStock() ?? defaultStockData

  const [amount, setAmount] = React.useState(0)
  React.useEffect(() => {
    if (amount > 0 && userData.balance - amount * stockData[currentStock] >= 0) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [amount])

  const [buttonDisabled, setButtonDisabled] = React.useState(true)

  const handleBuyingTransaction = () => {
    try {
      buyStock(currentStock, stockData[currentStock], amount, uid)
      toast.success(amount + '주를 매수 완료했어요')
      navigate('/stock/home')
    } catch (e) {
      toast.error(String(e))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (!stocks.includes(id!)) {
    navigate('/wtf')
  }
  return (
    <Stack>
      <Stack direction='row' alignItems='center' spacing={2}>
        <img src={stockMetadata[currentStock].img} height='40px' />
        <Typography fontSize={20}>
          <b>{stockMetadata[currentStock].name}</b>
        </Typography>
      </Stack>
      <Box height={30} />
      <Typography fontSize={16}>현재 1주당 가격</Typography>
      <Typography fontSize={28}>
        <b>{moneyFormat(stockData[currentStock])}원</b>
      </Typography>
      <Box height={20} />

      <input
        placeholder='몇 주를 매수할까요?'
        type='number'
        className='money-input'
        value={amount === 0 ? undefined : amount}
        onChange={(e) => {
          setAmount(Number(e.target.value))
        }}
      />
      <Typography>
        {stockData[currentStock] == 0
          ? '상장폐지된 종목은 거래할 수 없어요'
          : amount === 0
          ? '남은 돈 ' +
            moneyFormat(userData.balance) +
            '원으로 최대 ' +
            Math.floor(userData.balance / (stockData[currentStock] * 1.00015)) +
            '주 살 수 있어요'
          : userData.balance - amount * (stockData[currentStock] * 1.00015) >= 0
          ? '사고 나면 ' +
            moneyFormat(
              userData.balance -
                amount * stockData[currentStock] -
                Math.floor(amount * stockData[currentStock] * 0.00015),
            ) +
            '원 남아요'
          : '돈이 부족해요'}
      </Typography>
      {amount > 0 && userData.balance - amount * stockData[currentStock] >= 0 ? (
        <Typography sx={{ color: '#757575' }}>
          *매수수수료 0.015% ({Math.floor(amount * stockData[currentStock] * 0.00015)}원) 부과
        </Typography>
      ) : null}

      <div className='fd'>
        <button
          className='fb'
          onClick={() => handleBuyingTransaction()}
          disabled={buttonDisabled || stockData[currentStock] === 0}
        >
          매수
        </button>
      </div>
    </Stack>
  )
}

export default Buy
