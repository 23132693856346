import { createTheme, ThemeOptions } from '@mui/material'

const options: ThemeOptions = {
  typography: {
    fontFamily: 'Pretendard',
    // h1: {
    //   fontWeight: 'bold',
    // },
    // h2: {
    //   fontWeight: 'bold',
    // },
    // h3: {
    //   fontWeight: 'bold',
    // },
    // h4: {
    //   fontWeight: 'bold',
    // },
    // h5: {
    //   fontWeight: 'bold',
    // },
    // h6: {
    //   fontWeight: 'bold',
    // },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        style: {
          backgroundColor: '#f3f4f6',
          padding: 20,
          paddingTop: 25,
          paddingBottom: 25,
          borderRadius: 25,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
    },
  },
  palette: {
    primary: {
      main: '#3182f7',
      //   light: '#ba68c8',
      //   dark: '#7b1fa2',
    },
    //   secondary: {
    //     main: '#1976d2',
    //     light: '#42a5f5',
    //     dark: '#1565c0',
    //   },
  },
}

export const theme = createTheme(options)
